<template lang="pug">
  v-select.per-page-select(
    label="Rows per page"
    :items="options"
    :value="currentPerPageCount"
    @change="setCountPerPage"
  )
</template>

<script>
import { DEFAULT_PAGINATION, DEFAULT_COUNT_LIST } from '@/util/const'
import { PerPageStorage } from '@/models/perPageStorage.js'

export default {
  data: () => ({
    options: DEFAULT_COUNT_LIST,
    storage: null,
  }),
  mounted() {
    this.init();
  },
  computed: {
    currentPerPageCount() {
      if (this.storage) {
        return this.storage.getPerPageCount()
      }
      return DEFAULT_PAGINATION.SIZE;
    }
  },
  methods: {
    init() {
      this.storage = new PerPageStorage();
    },
    setCountPerPage(value) {
      this.storage.savePerPageCount(value);
      this.$emit('change', value);
    }
  }
}
</script>

<style lang="scss" scoped>

.per-page-select {
  max-width: 100px;
  width: 100%;
  position: absolute;
  bottom: -5px;
}

</style>
